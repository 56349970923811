<template>
	<div class="grid">
		<div class="md:col-8 col-12">
			<Card class="mb-4">
				<template #title>
					{{title}}
				</template>
				<template #content>
					<TabView>
						<TabPanel header="Profile">
							<div class="p-fluid">
								<div class="grid">
                                    <div class="sm:col-6 col-12">
                                        <div class="field">
                                            <label>Nama</label>
                                            <InputText v-model="profileForm.nama"
                                                :class="{ 'p-invalid': profileForm.errors.has('nama') }" />
                                            <small class="p-error" v-show="profileForm.errors.has('nama')">
                                                {{ profileForm.errors.get('nama') }}
                                            </small>
                                        </div>
                                    </div>
                                    <div class="sm:col-6 col-12">
                                        <div class="field">
                                            <label>NTA</label>
                                            <InputText v-model="profileForm.nta" disabled
                                                :class="{ 'p-invalid': profileForm.errors.has('nta') }" />
                                            <small class="p-error" v-show="profileForm.errors.has('nta')">
                                                {{ profileForm.errors.get('nta') }}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="grid">
                                    <div class="sm:col-6 col-12">
                                        <div class="field">
                                            <label>NIK</label>
                                            <InputText v-model="profileForm.nik" disabled
                                                :class="{ 'p-invalid': profileForm.errors.has('nik') }" />
                                            <small class="p-error" v-show="profileForm.errors.has('nik')">
                                                {{ profileForm.errors.get('nik') }}
                                            </small>
                                        </div>
                                    </div>
                                    <div class="sm:col-6 col-12">
                                        <div class="field">
                                            <label>Nama Instansi</label>
                                            <InputText v-model="profileForm.nama_instansi"
                                                :class="{ 'p-invalid': profileForm.errors.has('nama_instansi') }" />
                                            <small class="p-error" v-show="profileForm.errors.has('nama_instansi')">
                                                {{ profileForm.errors.get('nama_instansi') }}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="grid">
                                    <div class="sm:col-6 col-12">
                                        <div class="field">
                                            <label>No NUPTK/NIDN</label>
                                            <InputText v-model="profileForm.no_n"
                                                :class="{ 'p-invalid': profileForm.errors.has('no_n') }" />
                                            <small class="p-error" v-show="profileForm.errors.has('no_n')">
                                                {{ profileForm.errors.get('no_n') }}
                                            </small>
                                        </div>
                                    </div>
                                    <div class="sm:col-6 col-12">
                                        <div class="field">
                                            <label>Tanggal Lahir</label>
                                            <Calendar v-model="profileForm.tgl_lahir" :monthNavigator="true" :yearNavigator="true" :yearRange="yearRange"
                                                :class="{ 'p-invalid': profileForm.errors.has('tgl_lahir') }" />
                                            <small class="p-error" v-show="profileForm.errors.has('tgl_lahir')">
                                                {{ profileForm.errors.get('tgl_lahir') }}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="grid">
                                    <div class="sm:col-6 col-12">
                                        <div class="field">
                                            <label>No HP</label>
                                            <InputText v-model="profileForm.no_hp"
                                                :class="{ 'p-invalid': profileForm.errors.has('no_hp') }" />
                                            <small class="p-error" v-show="profileForm.errors.has('no_hp')">
                                                {{ profileForm.errors.get('no_hp') }}
                                            </small>
                                        </div>
                                    </div>
                                    <div class="sm:col-6 col-12">
                                        <div class="field">
                                            <label>Email</label>
                                            <InputText v-model="profileForm.email"
                                                :class="{ 'p-invalid': profileForm.errors.has('email') }" />
                                            <small class="p-error" v-show="profileForm.errors.has('email')">
                                                {{ profileForm.errors.get('email') }}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="field">
                                    <label>Alamat</label>
                                    <Textarea v-model="profileForm.alamat"
                                        :class="{ 'p-invalid': profileForm.errors.has('alamat') }" />
                                    <small class="p-error" v-show="profileForm.errors.has('alamat')">
                                        {{ profileForm.errors.get('alamat') }}
                                    </small>
                                </div>
                                <div class="grid">
                                    <div class="sm:col-6 col-12">
                                        <div class="field">
                                            <label>Provinsi</label>
                                            <Dropdown v-model="profileForm.province_id" optionValue="id" optionLabel="name" :filter="true"
                                                @change="getRegencies(profileForm.province_id, null, true)"
                                                :options="relations.provinces"
                                                :class="{ 'p-invalid': profileForm.errors.has('province_id') }" />
                                            <small class="p-error" v-show="profileForm.errors.has('province_id')">
                                                {{ profileForm.errors.get('province_id') }}
                                            </small>
                                        </div>
                                    </div>
                                    <div class="sm:col-6 col-12">
                                        <div class="field">
                                            <label>Kabupaten/Kota</label>
                                            <Dropdown v-model="profileForm.regency_id" optionValue="id" optionLabel="name" :filter="true"
                                                @change="getDistricts(profileForm.regency_id, null, true)"
                                                :options="relations.regencies"
                                                :class="{ 'p-invalid': profileForm.errors.has('regency_id') }" />
                                            <small class="p-error" v-show="profileForm.errors.has('regency_id')">
                                                {{ profileForm.errors.get('regency_id') }}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="grid">
                                    <div class="sm:col-6 col-12">
                                        <div class="field">
                                            <label>Kecamatan</label>
                                            <Dropdown v-model="profileForm.district_id" optionValue="id" optionLabel="name" :filter="true"
                                                @change="getVillages(profileForm.district_id)"
                                                :options="relations.districts"
                                                :class="{ 'p-invalid': profileForm.errors.has('district_id') }" />
                                            <small class="p-error" v-show="profileForm.errors.has('district_id')">
                                                {{ profileForm.errors.get('district_id') }}
                                            </small>
                                        </div>
                                    </div>
                                    <div class="sm:col-6 col-12">
                                        <div class="field">
                                            <label>Desa/Kelurahan</label>
                                            <Dropdown v-model="profileForm.village_id" optionValue="id" optionLabel="name" :filter="true"
                                                :options="relations.villages"
                                                :class="{ 'p-invalid': profileForm.errors.has('village_id') }" />
                                            <small class="p-error" v-show="profileForm.errors.has('village_id')">
                                                {{ profileForm.errors.get('village_id') }}
                                            </small>
                                        </div>
                                    </div>
                                </div>
							</div>
							<Button label="Save" v-if="$can('account-member-update')" @click="submitProfile" :disabled="profileForm.busy" :icon="(profileForm.busy) ? 'pi pi-spin pi-spinner' : 'pi pi-save'" />
						</TabPanel>
						<TabPanel header="Change Password">
							<div class="p-fluid">
								<div class="field">
									<label>New Password</label>
									<Password v-model="passwordForm.password" mediumRegex="^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})." toggleMask
										:class="{ 'p-invalid': passwordForm.errors.has('password') }">
										<template #footer>
											<Divider />
											<p class="mt-2">Suggestions</p>
											<ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
												<li>At least one lowercase</li>
												<li>At least one uppercase or one number</li>
												<li>Minimum 6 characters</li>
											</ul>
										</template>
									</Password>
									<small class="p-error" v-show="passwordForm.errors.has('password')">
										{{ passwordForm.errors.get('password') }}
									</small>
								</div>
								<div class="field">
									<label>Confirm Password</label>
									<Password v-model="passwordForm.password_confirmation" :feedback="false" toggleMask
										:class="{ 'p-invalid': passwordForm.errors.has('password_confirmation') }" />
									<small class="p-error" v-show="passwordForm.errors.has('password_confirmation')">
										{{ passwordForm.errors.get('password_confirmation') }}
									</small>
								</div>
							</div>
							<Button label="Save" v-if="$can('account-member-update')" @click="submitPassword" :disabled="passwordForm.busy" :icon="(passwordForm.busy) ? 'pi pi-spin pi-spinner' : 'pi pi-save'" />
						</TabPanel>
					</TabView>
				</template>
			</Card>

			<Card>
				<template #title>
					Bukti Transfer
				</template>
				<template #content>
					<Fieldset>
						<template #legend>
							<i class="pi pi-info-circle"></i>
						</template>
						<div v-html="bukti_transfer_message"></div>
					</Fieldset>
					<Divider />
					<Message v-if="dataMember.bukti_transfer.pesan != null && dataMember.bukti_transfer.status == '0'" severity="error">
						{{dataMember.bukti_transfer.pesan}}
					</Message>
					<div class="layout-profile bukti-transfer">
						<Image :src="bukti_transfer" imageStyle="width: 100%; margin-left: -1px" preview />
					</div>
					<div v-if="dataMember.bukti_transfer.status == '0'" class="p-fluid">
						<div class="field">
							<label>Bukti Transfer</label> (maxsize:10mb)
							<InputText @change="selectBuktiTransfer" type="file" accept="image/*"
								:class="{ 'p-invalid': buktiTransferForm.errors.has('bukti_transfer') }" />
							<small class="p-error" v-show="buktiTransferForm.errors.has('bukti_transfer')">
								{{ buktiTransferForm.errors.get('bukti_transfer') }}
							</small>
						</div>
						<div class="field">
							<label>Nominal</label>
							<InputText v-model="buktiTransferForm.nominal" 
								:class="{ 'p-invalid': buktiTransferForm.errors.has('nominal') }" />
							<small class="p-error" v-show="buktiTransferForm.errors.has('nominal')">
								{{ buktiTransferForm.errors.get('nominal') }}
							</small>
						</div>
						<div class="field">
							<Button label="Upload" v-if="$can('account-member-update')" @click="submitBuktiTransfer" :disabled="buktiTransferForm.busy" :icon="(buktiTransferForm.busy) ? 'pi pi-spin pi-spinner' : 'pi pi-save'" />
						</div>
					</div>
				</template>
			</Card>
		</div>

		<div class="md:col-4 col-12">
			<Card class="mb-4">
				<!-- <template #title>
					{{title}}
				</template> -->
				<template #content>
					<div class="layout-profile">
						<Avatar :image="avatar" size="xlarge" class="avatar-xxl" shape="circle" alt="" />
						<h5>{{userCredentials.user.name}}</h5>
						<Tag v-for="role in userCredentials.roles" :key="role" class="mr-2">{{role}}</Tag>
					</div>
					<div class="p-fluid">
						<div class="field">
							<label>Pasfoto</label> (maxsize:10mb)
							<InputText @change="selectFileAvatar" type="file" accept="image/*"
								:class="{ 'p-invalid': avatarForm.errors.has('avatar') }" />
							<small class="p-error" v-show="avatarForm.errors.has('avatar')">
								{{ avatarForm.errors.get('avatar') }}
							</small>
						</div>
					</div>
					<Button label="Upload" v-if="$can('account-member-update')" @click="submitAvatar" :disabled="avatarForm.busy" :icon="(avatarForm.busy) ? 'pi pi-spin pi-spinner' : 'pi pi-save'" />
				</template>
			</Card>

			<Card>
				<!-- <template #title>
					{{title}}
				</template> -->
				<template #content>
					<div class="layout-profile foto-ktp">
						<Image :src="foto_ktp" imageStyle="width: 100%; margin-left: -1px" preview />
					</div>
					<div class="p-fluid">
						<div class="field">
							<label>Foto KTP</label> (maxsize:10mb)
							<InputText @change="selectFileKTP" type="file" accept="image/*"
								:class="{ 'p-invalid': ktpForm.errors.has('foto_ktp') }" />
							<small class="p-error" v-show="ktpForm.errors.has('foto_ktp')">
								{{ ktpForm.errors.get('foto_ktp') }}
							</small>
						</div>
					</div>
					<Button label="Upload" v-if="$can('account-member-update')" @click="submitKTP" :disabled="ktpForm.busy" :icon="(ktpForm.busy) ? 'pi pi-spin pi-spinner' : 'pi pi-save'" />
				</template>
			</Card>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		userCredentials: {},
	},
	data() {
		return {
			title: 'User Profile',
			api: '/api/account-member',
			dataMember: {
				bukti_transfer: {}
			},
			bukti_transfer_message: null,
			profileForm: new this.$FormAuth({
				// id: null,
				nama: null,
				nta: null,
				nik: null,
				nama_instansi: null,
				no_n: null,
				tgl_lahir: null,
				no_hp: null,
				email: null,
				alamat: null,
				province_id: null,
				regency_id: null,
				district_id: null,
				village_id: null,
			}),
			passwordForm: new this.$FormAuth({
				// id: null,
				password: null,
				password_confirmation: null,
			}),
			avatarForm: new this.$FormAuth({
				// id: null,
				avatar: null,
			}),
			ktpForm: new this.$FormAuth({
				// id: null,
				foto_ktp: null,
			}),
			buktiTransferForm: new this.$FormAuth({
				// id: null,
				bukti_transfer: null,
				nominal: null,
			}),
            relations: {
				provinces: null,
				regencies: null,
				districts: null,
				villages: null,
			},
		}
	},
	computed: {
		avatar(){
			return (this.userCredentials.user.avatar) ? process.env.VUE_APP_ASSETS_FOLDER + '/user_avatar/' + this.userCredentials.user.avatar : process.env.VUE_APP_DEFAULT_AVATAR;
		},
		foto_ktp(){
			return (this.dataMember.foto_ktp) ? process.env.VUE_APP_ASSETS_FOLDER + '/member_foto_ktp/' + this.dataMember.foto_ktp : process.env.VUE_APP_DEFAULT_NO_IMAGE;
		},
		bukti_transfer(){
			return (this.dataMember.bukti_transfer.file) ? process.env.VUE_APP_ASSETS_FOLDER + '/member_bukti_transfer/' + this.dataMember.bukti_transfer.file : process.env.VUE_APP_DEFAULT_NO_IMAGE;
		},
        yearRange() {
			const now = new Date().getFullYear();
			const past = now-100;
			const yearRange = past+':'+now;
            return yearRange;
        },
	},
	methods: {
		loadUserData(){
			this.$Progress.start();
			this.$httpAuth.get(this.api)
			.then((response) => {
				this.$Progress.finish();
				this.profileForm.fill(response.data.member);
				this.dataMember = response.data.member;
                this.getProvinces(response.data.member.province_id);
                this.getRegencies(response.data.member.province_id, response.data.member.regency_id);
                this.getDistricts(response.data.member.regency_id, response.data.member.district_id);
                this.getVillages(response.data.member.district_id, response.data.member.village_id);

				this.buktiTransferForm.nominal = response.data.member.bukti_transfer.nominal;
				this.bukti_transfer_message = response.data.bukti_transfer_message;
			})
			.catch((error) => {
				this.$Progress.fail();
				this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
			});
		},

		selectFileAvatar(e){
			const file = e.target.files[0];
			this.avatarForm.avatar = file;
		},
		selectFileKTP(e){
			const file = e.target.files[0];
			this.ktpForm.foto_ktp = file;
		},
		selectBuktiTransfer(e){
			const file = e.target.files[0];
			this.buktiTransferForm.bukti_transfer = file;
		},
        getProvinces(current_id, resetTree = false){
			this.$Progress.start();
			this.$httpAuth.get(this.api + '/get/provinces')
			.then((response) => {
				this.$Progress.finish();
				this.relations.provinces = response.data;
				if(current_id == null){
					this.profileForm.province_id = null;
				} else {
					this.profileForm.province_id = current_id;
				}
				if(resetTree == true){
					this.profileForm.regency_id = null;
					this.profileForm.district_id = null;
					this.profileForm.village_id = null;
					this.relations.regencies = null;
					this.relations.districts = null;
					this.relations.villages = null;
				}
			})
			.catch((error) => {
				this.$Progress.fail();
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
		},
		getRegencies(province_id, current_id, resetTree = false){
			this.$Progress.start();
			this.$httpAuth.get(this.api + '/get/regencies', {
				params: {
					province_id: province_id,
				}
			})
			.then((response) => {
				this.$Progress.finish();
				this.relations.regencies = response.data;
				if(current_id == null){
					this.profileForm.regency_id = null;
				} else {
					this.profileForm.regency_id = current_id;
				}
				if(resetTree == true){
					this.profileForm.district_id = null;
					this.profileForm.village_id = null;
					this.relations.districts = null;
					this.relations.villages = null;
				}
			})
			.catch((error) => {
				this.$Progress.fail();
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
		},
		getDistricts(regency_id, current_id, resetTree = false){
			this.$Progress.start();
			this.$httpAuth.get(this.api + '/get/districts', {
				params: {
					regency_id: regency_id,
				}
			})
			.then((response) => {
				this.$Progress.finish();
				this.relations.districts = response.data;
				if(current_id == null){
					this.profileForm.district_id = null;
				} else {
					this.profileForm.district_id = current_id;
				}
				if(resetTree == true){
					this.profileForm.village_id = null;
					this.relations.villages = null;
				}
			})
			.catch((error) => {
				this.$Progress.fail();
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
		},
		getVillages(district_id, current_id){
			this.$Progress.start();
			this.$httpAuth.get(this.api + '/get/villages', {
				params: {
					district_id: district_id,
				}
			})
			.then((response) => {
				this.$Progress.finish();
				this.relations.villages = response.data;
				if(current_id == null){
					this.profileForm.village_id = null;
				} else {
					this.profileForm.village_id = current_id;
				}
			})
			.catch((error) => {
				this.$Progress.fail();
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
		},

		submitProfile(){
			this.$Progress.start();
			this.profileForm.post(this.api)
			.then((response) => {
				this.$Progress.finish();
				this.$toast.add({severity:'success', summary: 'Success', detail:response.data.message, life: 3000});
				this.$e.emit('getUserCredentials');
			})
			.catch((error) => {
				this.$Progress.fail();
				this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
			});
		},
		submitPassword(){
			this.$Progress.start();
			this.passwordForm.post(this.api + '/password')
			.then((response) => {
				this.$Progress.finish();
				this.$toast.add({severity:'success', summary: 'Success', detail:response.data.message, life: 3000});
			})
			.catch((error) => {
				this.$Progress.fail();
				this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
			});
		},
		submitAvatar(){
			this.$Progress.start();
			this.avatarForm.post(this.api + '/avatar-member')
			.then((response) => {
				this.$Progress.finish();
				this.$toast.add({severity:'success', summary: 'Success', detail:response.data.message, life: 3000});
				this.$e.emit('getUserCredentials');
			})
			.catch((error) => {
				this.$Progress.fail();
				this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
			});
		},
		submitKTP(){
			this.$Progress.start();
			this.ktpForm.post(this.api + '/foto-ktp')
			.then((response) => {
				this.$Progress.finish();
				this.$toast.add({severity:'success', summary: 'Success', detail:response.data.message, life: 3000});
				this.loadUserData();
			})
			.catch((error) => {
				this.$Progress.fail();
				this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
			});
		},
		submitBuktiTransfer(){
			this.$Progress.start();
			this.buktiTransferForm.post(this.api + '/bukti-transfer')
			.then((response) => {
				this.$Progress.finish();
				this.$toast.add({severity:'success', summary: 'Success', detail:response.data.message, life: 3000});
				this.loadUserData();
			})
			.catch((error) => {
				this.$Progress.fail();
				this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
			});
		},
	},
	beforeCreate(){},
	created(){
		this.$e.emit('updateTitle', this.title);
	},
	mounted() {
		this.loadUserData();
	},
}
</script>

<style scoped>
.foto-ktp > img, .bukti-transfer > img {
	width: 95%;
}
</style>